@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
     url("./assets/fonts/Poppins-Black.ttf") format("truetype");
    font-weight: 900;
   }
@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
     url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
    font-weight: 700;
   }
@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
     url("./assets/fonts/Poppins-ExtraBold.ttf") format("truetype");
    font-weight: 800;
   }
@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
     url("./assets/fonts/Poppins-ExtraLight.ttf") format("truetype");
    font-weight: 100;
   }
@font-face {
    font-family: "Poppins";
    src: local("Poppins"),
     url("./assets/fonts/Poppins-Light.ttf") format("truetype");
    font-weight: 300;
   }
   
   @font-face {
    font-family: "Poppins";
    src: local("Poppins"),
     url("./assets/fonts/Poppins-Medium.ttf") format("truetype");
    font-weight: 600;
   }


   @font-face {
    font-family: "Poppins";
    src: local("Poppins"),
     url("./assets/fonts/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 700;
   }
   @font-face {
    font-family: "Poppins";
    src: local("Poppins"),
     url("./assets/fonts/Poppins-Thin.ttf") format("truetype");
    font-weight: 100;
   }
   @font-face {
    font-family: "Poppins";
    src: local("Poppins"),
     url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: 400;
   }